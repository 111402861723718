<template>
  <div style="padding: 40px;">Будет большой красивый монитор</div>
</template>

<script>
export default {

  name: 'Monitor',

};
</script>
